enum Theme {
  Default = 'default',
  OlympischeSpelen2024 = 'olympische-spelen-2024',
  ParalympischeSpelen2024 = 'paralympische-spelen-2024',
  GenericPlay = 'generic-play'
}

type ThemeConfig = {
  theme?: Theme,
  type?: string,
  [key: string]: any
}

const OlympischeSpelenColors: Record<string, string> = {
  '#009CE0': '##FBA9CB',
  '#022366': '#009CE0',
  '#570047': '#FF5757',
  '#003853': '#17B7B0',
  '#331362': '#7D84C2'
}

const colorKeys = Object.keys(OlympischeSpelenColors)

const getThemeColorFromValue = (value?: string) => {
  const hash = value?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) ?? 0
  const colorIndex = hash % colorKeys.length

  return {
    '--avatar-border-color': colorKeys[colorIndex],
    '--avatar-border-color-darker': OlympischeSpelenColors[colorKeys[colorIndex]]
  }
}

export type {
  ThemeConfig
}

export {
  Theme,
  OlympischeSpelenColors,
  getThemeColorFromValue
}
