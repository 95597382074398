import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../../../molecules/button'
import type { EventSetProps } from '../../../../molecules/field-timeline/event-set'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { ScoreScoreProps } from '../../../../molecules/score/layout'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { TableProps } from '../../../table'

import clsx from 'clsx'

import Title from '../../../../atoms/title'
import Button, { ButtonVariant } from '../../../../molecules/button'
import Heading from '../../../../molecules/heading'
import Score, { ScoreLayoutTypes, SportTypes, StatusTypes } from '../../../../molecules/score'
import Teamname from '../../../../molecules/teamname'
import { ScoreboardContext } from '../../config'
import { ScoreboardLayoutTypes } from '../../index'
import styles from '../../scoreboard.module.scss'

interface ScoreboardScoreComponent {
  componentProps: ScoreboardScoreProps
}

interface ScoreboardScoreProps extends ScoreScoreProps {
  away: TeamnameProps['team'] | [PlayernameProps['player']]
  competitionName?: string
  darkMode?: boolean
  date?: string
  designSystemBaseUrl?: string
  eventSets?: EventSetProps[]
  goals?: TableProps
  home: TeamnameProps['team'] | [PlayernameProps['player']]
  isLastItem?: boolean
  label?: string
  layout?: ScoreboardLayoutTypes
  left?: string
  meta?: string
  relatedMatch?: ScoreboardScoreComponent
  right?: string
  showHeader?: boolean
  showLinks?: boolean
  showLogos?: boolean
  sport: SportTypes | string
  status?: StatusTypes | string
  url?: string
  channels?: any
  context?: ScoreboardContext
}

const DefaultScoreBoard: FunctionComponent<ScoreboardScoreProps> = (
  {
    away,
    competitionName,
    designSystemBaseUrl,
    home,
    isLastItem = false,
    label,
    layout,
    left,
    right,
    score,
    showHeader,
    showLinks,
    showLogos,
    status,
    sport,
    channels = [],
    context
  }
) => {
  const filteredChannels = channels.filter((channel: ButtonProps) => !channel.isHiddenFromMatchdays)

  return <>
    {showHeader && competitionName && <Heading className={styles.competitionHeading} variant={'secondary'}
                                               align={layout === 'compact' ? 'Left' : 'Center'}>{competitionName}</Heading>}
    {(left || right) && <div className={styles.scoreboardHeading}>
      {left && <Title>{left}</Title>}
      {right && <Title>{right}</Title>}
    </div>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <Teamname
        size={'Large'}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition={layout === 'compact' ? 'top' : 'left'}
      />

      <Score
        layout={ScoreLayoutTypes.Default}
        score={score}
        sport={sport}
        status={status}
        label={label}
      >
        {
          filteredChannels?.length
          && status != StatusTypes.Live
          && context
          && [ScoreboardContext.Matchday, ScoreboardContext.Event].includes(context)
            ? <div className={styles.channels}>
              {
                filteredChannels
                  .map((channel: ButtonProps) => {
                    const isPlay6 = channel.iconBefore && channel.iconBefore.indexOf('play-6') > -1

                    return <Button
                      {...channel}
                      href={undefined}
                      iconBefore={channel.iconBefore}
                      key={channel.iconBefore}
                      variant={ButtonVariant.tertiary}
                      className={clsx(
                        isPlay6 && styles.channelsPlay6,
                      )}
                    />
                  })
              }
            </div>
            : null
        }
      </Score>

      <Teamname
        size={'Large'}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition={layout === 'compact' ? 'top' : 'right'}
        isAway={true}
      />
    </div>
  </>
}

export default DefaultScoreBoard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}
