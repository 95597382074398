import type { CSSProperties, FunctionComponent } from 'react'
import type { EventProps } from '../index'

import clsx from 'clsx'

import Title, { TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Image from '../../../molecules/image'
import Cell from '../../cell'
import Grid from '../../grid'
import gridStyles from '../../grid/grid.module.scss'
import LiveDashboard, { LiveDashboardBackground } from '../../live-dashboard'
import Scoreboard, { ScoreboardLayoutTypes } from '../../scoreboard'
import { ScoreboardContext } from '../../scoreboard/config'
import Section from '../../section/section'
import styles from '../event.module.scss'
import EventArticles from '../partials/articles'

const EventDefault: FunctionComponent<EventProps> = (
  {
    title,
    articleSubTitle = 'laatste nieuws',
    matchSubTitle = 'wedstrijden',
    livestreamSubTitle = 'kijk live',
    link,
    calendarUrl,
    items,
    matchItems,
    livestreamSection,
    config = {},
    darkMode,
    analyticsId
  }
) => {
  const {
    accentColor = '#83FF00', //darkMode ? '#83FF00' : '#000',
    backgroundRightImageUrl,
    backgroundLeftImageUrl,
    logoUrl,
    iconUrl
  } = config

  const titleIcon = iconUrl && <img className={styles.titleIcon} src={iconUrl} alt={''}/>
  const titleElement = title
    && <Title
      className={styles.title}
      size={TitleSize.Medium}
      desktopSize={TitleSize.Large}
      link={link}
      darkMode={darkMode}
    >{title}</Title>

  const backgroundImageProfiles = [{
    name: 'w320hx',
    view: '640w'
  },{
    name: 'w960hx',
    view: '960w'
  }]

  const ebaData = {
    listAnalyticsId: analyticsId,
    listTitle: title,
    listElementCount: items.length,
    listType: 'eventblock'
  }

  const hasLivestreams = livestreamSection
    && livestreamSection?.componentProps
    && livestreamSection?.componentProps.items
    && livestreamSection?.componentProps?.items?.length > 0

  return <Grid className={clsx(
    styles.event,
    darkMode && styles.darkMode
  )}
    style={accentColor ? {
      '--color-brand-primary-500': accentColor,
      '--color-brand-primary-500-override': accentColor,
      '--link-decoration-color': accentColor,
      '--link-decoration-color-hover': accentColor,
      '--link-underline-color': accentColor,
      '--icon-color': accentColor,
      '--image-icon-color': accentColor,
      '--button-primary-background-hover': accentColor,
      '--button-primary-icon-color': accentColor,
      '--button-tertiary-icon-color': accentColor,
      '--button-tertiary-decoration-color': accentColor,
      '--button-tertiary-decoration-color-hover': accentColor,
      '--button-tertiary-icon-color-hover': accentColor
    } as CSSProperties : undefined}>
    <div className={styles.backgrounds}>
      {backgroundRightImageUrl && <Image
        profiles={backgroundImageProfiles}
        className={styles.backgroundRight}
        src={backgroundRightImageUrl}
        columns={2}
      />}
      {backgroundLeftImageUrl && <Image
        profiles={backgroundImageProfiles}
        className={styles.backgroundLeft}
        src={backgroundLeftImageUrl}
        columns={2}
      />}
    </div>
    <Cell
      size={4}
      className={styles.collapseX}
    >
      <div className={styles.eventHeader}>
        <span>
          {titleElement}
        </span>
        {logoUrl && <img className={styles.logo} src={logoUrl} />}
      </div>
    </Cell>
    {hasLivestreams && <>
      <Cell className={clsx(styles.collapseX, styles.livestreams)}>
        <Title size='Small' desktopSize='Medium' darkMode={darkMode} className={styles.subtitle}>
          {titleIcon} {livestreamSubTitle}
          {calendarUrl && <Button className={styles.programButton} variant="tertiary" darkMode={darkMode} iconAfter='chevron-right' href={calendarUrl}>volledige uitzendschema</Button>}
        </Title>
        <Section {...livestreamSection.componentProps} />
      </Cell>
    </>}
    {matchItems && <>
      <Cell className={styles.collapseX}>
        {matchItems.length === 1
          ? <Scoreboard className={styles.bannerScoreboard} {...matchItems[0]?.componentProps} layout={ScoreboardLayoutTypes.Banner} darkMode={darkMode} context={ScoreboardContext.Event} />
          : <>
            <Title size='Small' desktopSize='Medium' darkMode={darkMode} className={styles.subtitle}>
              {titleIcon} {matchSubTitle}
              {calendarUrl && <Button className={styles.programButton} variant="tertiary" darkMode={darkMode} iconAfter='chevron-right' href={calendarUrl}>bekijk alles</Button>}
            </Title>
            <LiveDashboard darkMode={darkMode} items={[{ items: matchItems }]} maxItems={4} background={LiveDashboardBackground.None} context={ScoreboardContext.Event} />
          </>
        }
      </Cell>
    </>}
    {items.length > 0 &&
      <Cell size={4} className={gridStyles.collapse}>
        {(matchItems || hasLivestreams) && <Title className={styles.subtitle} size='Small' desktopSize='Medium' darkMode={darkMode}>{titleIcon} {articleSubTitle}</Title>}
        <EventArticles
          darkMode={darkMode}
          items={items}
          link={link}
          logoUrl={logoUrl}
          ebaData={ebaData}
        />
      </Cell>
    }
    <div className={styles.background}></div>
  </Grid>
}

export default EventDefault
